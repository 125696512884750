import { Disposable } from '../creation/applicationConstructor';
import { clearChangesInGlobalObjects } from './cleanUpGlobalObjectChanges';

export type CleanUpFunction = () => void;

export const prepareCleanUpFunction =
  (instance: Disposable, rootElement: HTMLElement): CleanUpFunction =>
  () => {
    instance.destroy();
    rootElement.remove();
    // cleaning globals must be after all ember clean up;
    // you have to initialize ember (e.g. load vendor again) after such clean up before new app initialization
    setTimeout(clearGlobalChanges, 0);
  };

const tryToClearWindowProperty = (propertyKey: string | symbol) => {
  try {
    window[propertyKey] = undefined;
  } catch (error) {
    // https://jira.shijidev.com/browse/ACPR-73499
    // in case of configurable property without a setter, redefine property and set to undefined
    // "delete window[overriddenGlobalVariable]" also can resolve this issue but using delete
    // may have performance impact.
    if (
      error instanceof TypeError &&
      Object.getOwnPropertyDescriptor(window, propertyKey)?.configurable
    ) {
      // delete window[overriddenGlobalVariable];
      Object.defineProperty(window, propertyKey, {
        value: undefined,
        configurable: true,
        writable: true,
        enumerable: true,
      });
    } else {
      // clear message for possible issues in future
      throw new Error(
        `unable to cleanup ember global variable: "${String(
          propertyKey
        )}" \r\n ${error}`
      );
    }
  }
};

export const clearGlobalChanges = () => {
  clearGlobalVariables();
  clearChangesInGlobalObjects();
};

const clearGlobalVariables = () => {
  overriddenGlobalVariables.forEach((overriddenGlobalVariable) =>
    tryToClearWindowProperty(overriddenGlobalVariable)
  );
  overriddenSymbolicGlobalVariables.forEach(
    (overriddenSymbolicGlobalVariable) =>
      tryToClearWindowProperty(Symbol.for(overriddenSymbolicGlobalVariable))
  );
};

const overriddenGlobalVariables = [
  'runningTests',
  'loader',
  'requireModule',
  'require',
  'requirejs',
  'createDeprecatedModule',
  '__ember_auto_import__',
  'IPv6',
  'punycode',
  'SecondLevelDomains',
  'URI',
  'URITemplate',
  'moment',
  '$',
  'jQuery',
  'mainContext',
  'Ember',
  'Em',
  'dependencyLib',
  'Tether',
  'FakeXMLHttpRequest',
  'RouteRecognizer',
  'Pretender',
  'faker',
  'webpackJsonp_ember_auto_import_',
  '_eai_r',
  '_eai_d',
  'emberAutoImportDynamic',
  'emberDefine',
  'preferNative',
  'Highcharts',
  'deepmerge',
  'devtoolsFormatters',

  // ---------
  // globals which clean up caused problems:
  // "deprecationWorkflow",

  // ---------
  // globals present only in ember:
  // LiveReload,

  // ---------
  // additional globals which are new in ember app but are not added when loading to react (they are already present)
  // they are connected with single-spa
  // "PubSub",
  // "addEventListener",
  // "removeEventListener",
  // "singleSpaNavigate",
];

const overriddenSymbolicGlobalVariables = ['GLIMMER_VALIDATOR_REGISTRATION'];
