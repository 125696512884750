import { PURIFY_PATTERNS } from '../../constants';

const PLAIN_STRING_PATTERNS = [
  ...PURIFY_PATTERNS,
  {
    pattern: /<\/?[^>]+(>|$)/g,
    replacement: '',
  },
];

export const getPlainTextFromHtmlStrings = (value: string) => {
  return PLAIN_STRING_PATTERNS.reduce(
    (stringValue, f) => stringValue.replace(f.pattern, f.replacement),
    value
  );
};
