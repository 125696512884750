export const PURIFY_PATTERNS = [
  {
    pattern: /&nbsp;/g,
    replacement: ' ',
  },
  {
    pattern: /&amp;/g,
    replacement: '&',
  },
  {
    pattern: /&lt;/g,
    replacement: '<',
  },
  {
    pattern: /&gt;/g,
    replacement: '>',
  },
];
